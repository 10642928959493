import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  sideBarLabels: any;
  sideBarLabelUser: any;
  engagementLabel:any;
  siteInfoLabel:any;
  activepath:any;
  currentUserDetail: any;
  constructor(private route: Router) { }

  ngOnInit() {
    localStorage.getItem('labelLanguage') ? 
    this.sideBarLabels = JSON.parse(localStorage.getItem("allLabels"))!.admin!.drawer[localStorage.getItem('labelLanguage')] : this.sideBarLabels = JSON.parse(localStorage.getItem("allLabels"))!.admin!.drawer!.en;
    // console.log("🚀 ~ file: sidebar.component.ts:17 ~ SidebarComponent ~ ngOnInit ~  this.sideBarLabels",  this.sideBarLabels)
    localStorage.getItem('labelLanguage') ? 
    this.sideBarLabelUser = JSON.parse(localStorage.getItem("allLabels"))!.admin!.user[localStorage.getItem('labelLanguage')] : this.sideBarLabelUser = JSON.parse(localStorage.getItem("allLabels"))!.admin!.user!.en;    
    // console.log("🚀 ~ file: sidebar.component.ts:20 ~ SidebarComponent ~ ngOnInit ~ this.sideBarLabelUser", this.sideBarLabelUser)

    localStorage.getItem('labelLanguage') ? this.engagementLabel = JSON.parse(localStorage.getItem("allLabels"))!.admin!.engagement[localStorage.getItem('labelLanguage')] : this.sideBarLabelUser = JSON.parse(localStorage.getItem("allLabels"))!.admin!.engagement!.en 
    // console.log("🚀 engagementLabel", this.engagementLabel)
    if(localStorage.getItem("path") !== null){
      this.activepath = localStorage.getItem("path")
    }
    // console.log("Active path",this.activepath)
    this.currentUserDetail = JSON.parse(localStorage.getItem('currentUser')).result;
    // console.log(this.currentUserDetail ,"zzzzz");
    
  }
  changeLanguage(language) {
    localStorage.setItem('labelLanguage', language);
    this.sideBarLabels = JSON.parse(localStorage.getItem("allLabels"))!.admin!.drawer[localStorage.getItem('labelLanguage')];
    localStorage.setItem('labelLanguage', language);
    this.sideBarLabelUser = JSON.parse(localStorage.getItem("allLabels"))!.admin!.user[localStorage.getItem('labelLanguage')];

    localStorage.setItem('labelLanguage', language);
    this.engagementLabel = JSON.parse(localStorage.getItem("allLabels"))!.admin!.engagement[localStorage.getItem('labelLanguage')];
  }
  logOutUser(){
    localStorage.clear();
    this.route.navigate(['/login']);
  }
  setActive(path){
    localStorage.setItem("path",path)
    this.activepath = localStorage.getItem("path")
    // console.log("ActivePath",this.activepath)
  }
}
