import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "search",
})
export class SearchPipe implements PipeTransform {
  transform(value: any[], args: string): any[] {
    return value.filter((val) => {
      if (val.mobile) {
        // console.log(val.mobile,"dgfxgcgffgsfcgfc");
        
        let rVal = val.mobile.toString().toLowerCase().includes(args);
        return rVal;
      }
    });
  }
}
