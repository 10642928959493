import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from "./models/user.model";

// const localUrl = 'assets/data/smartphone.json';

@Injectable({
  providedIn: "root",
})
export class LoginService {
  apiurl: string = environment.url;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(data) {
    return this.http
      .post<any>(
       environment.baseURLAuth +`/adminLogin`,
        data
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }
  // login(){
  //   return this.http.post('stgapi.pmccare.in/')
  // }

  getLogin(): Observable<any> {
    return this.http.get(`${this.apiurl}/v1/lablesctrl/getlabels`);
  }
}
