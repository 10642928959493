// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  url: "https://api.digipmc.foxberry.link/applicationConfig",
  apiURL: 'https://api.digipmc.foxberry.link/user/v1',
  apiURLs: 'https://api.digipmc.foxberry.link/userEngagement/v1',
  apiURLNEAR: 'https://api.digipmc.foxberry.link/nearMe/v1',
  apiSERVICEFAQ: 'https://api.digipmc.foxberry.link/info/v1',
  apisystemservice: 'https://api.digipmc.foxberry.link/applicationConfig/v1/',
  apiWebinar: 'https://api.digipmc.foxberry.link/user/v1/WebinarCtrl',
  approvalCtrl: 'https://api.digipmc.foxberry.link/userEngagement/v1/ApprovalCtrl/',
  baseURLUser: 'https://api.digipmc.foxberry.link/user',
  baseURL: 'https://api.digipmc.foxberry.link/',
  baseURLAuth: 'https://api.digipmc.foxberry.link/authenticationConfiguration/v1',
  storeObject: 'https://api.digipmc.foxberry.link/objectStorage/v1',
  shareUrl:'https://app.cmsdigipmc.foxberry.link/',
  firebase: {
    apiKey: "AIzaSyAQxS-erNv39iCrdl4dB4bvaC5OVm-xb-A",
    authDomain: "pmc-care-production.firebaseapp.com",
    projectId: "pmc-care-production",
    storageBucket: "pmc-care-production.appspot.com",
    messagingSenderId: "303091405400",
    appId: "1:303091405400:web:da2328a49777fb74adf02d",
    measurementId: "G-182E7Y1PWN"
  }
};






/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
