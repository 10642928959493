<nav
  class="main-menu"
  style="position: fixed; top: 60px; bottom: 0; overflow: scroll"
>
  <!-- <div class="settings"></div> -->
  <div class="side" id="style-1">
    <div class="ul-box pt-4">
      <!-- DASHBOARD -->
      <!-- <li>
                <a href="#/dashboard" data-toggle="collapse" data-target="#dashboard" aria-expanded="false">
                    <i class="fa fa-dashboard fa-lg"></i>
                    <span class="nav-text">Dashboard</span>
                </a>
            </li> -->

      <!--****************** USER *********************-->
      <li>
        <a
          href=""
          data-toggle="collapse"
          data-target="#submenuUser"
          aria-expanded="false"
        >
          <i class="fa fa-user fa-lg"></i>
          <span class="nav-text">{{ sideBarLabels.user }}</span>
        </a>
        <ul
          class="nav collapsable collapse"
          id="submenuUser"
          role="menu"
          aria-labelledby="btn-Settings"
        >
          <li
            [class]="
              activepath === '/user/citizen-list' ? 'activeItem' : 'dropItem'
            "
            (click)="setActive('/user/citizen-list')"
          >
            <a
              href="#"
              routerLink="/user/citizen-list"
              ng-class="navClass('citizenList')"
              id="menu-toggleadmin"
            >
              <i class="fa fa-users fa-lg"></i>
              <span class="nav-text">{{ sideBarLabelUser.citizen }}</span>
            </a>
          </li>
          <li
            [class]="
              activepath === '/user/merchant-list' ? 'activeItem' : 'dropItem'
            "
            (click)="setActive('/user/merchant-list')"
          >
            <a
              href="#"
              routerLink="/user/merchant-list"
              ng-class="navClass('merchantList')"
              id="menu-toggleadminmerchnat"
            >
              <i class="fa fa-briefcase fa-lg"></i>
              <span class="nav-text">{{ sideBarLabelUser.merchant }}</span>
            </a>
          </li>
          <!-- <li class="darkerli"><a href="#" routerLink="/user/bulk-upload-list"
                            ng-class="navClass('bulkUploadCovidData')" id=""><i
                                class="fa fa-user-circle fa-lg"></i><span
                                class="nav-text">{{sideBarLabels.bulkUploadCovidData}}</span></a>
                    </li> -->
        </ul>
      </li>

      <!--********************** Engagement **********************-->
      <li>
        <a
          href="#"
          data-toggle="collapse"
          data-target="#submenuEngagement"
          aria-expanded="false"
        >
          <i class="fa fa-rss-square fa-lg"></i>
          <span class="nav-text">{{ sideBarLabels.engagement }}</span>
        </a>
        <ul
          class="nav collapsable collapse"
          id="submenuEngagement"
          role="menu"
          aria-labelledby="btn-Settings"
        >
          <li
            [class]="
              activepath === '/engagement/blog-list' ? 'activeItem' : 'dropItem'
            "
            (click)="setActive('/engagement/blog-list')"
          >
            <a
              href="#"
              routerLink="/engagement/blog-list"
              ng-class="navClass('blogList')"
              id="menu-toggleadminBlog"
            >
              <i class="fa fa-rss fa-lg"></i>
              <span class="nav-text">{{ engagementLabel.blog }}</span>
            </a>
          </li>
          <!-- <li class="dropItem" ng-class="navClass('newsList')"><a href="#" routerLink="/engagement/news-list"
                            id="menu-toggleadminNews"><i class="fa fa-newspaper-o fa-lg"></i><span
                                class="nav-text">{{engagementLabel.news}}</span></a></li> -->
          <li [class]="activepath === '/engagement/event-list' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/engagement/event-list')" >
            <a
              href="#"
              routerLink="/engagement/event-list"
              ng-class="navClass('eventList')"
              id="menu-toggleadminEvents"
              ><i class="fa fa-calendar-o fa-lg"></i
              ><span class="nav-text">{{ engagementLabel.event }}</span></a
            >
          </li>
          <li [class]="activepath === '/engagement/article-list' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/engagement/article-list')" >
            <a
              href="#"
              routerLink="/engagement/article-list"
              ng-class="navClass('articleList')"
              id="menu-toggleadminArticle"
              ><i class="fa fa-pencil-square-o fa-lg"></i
              ><span class="nav-text">{{ engagementLabel.article }}</span></a
            >
          </li>
          <li [class]="activepath === '/engagement/competition-list' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/engagement/competition-list')" >
            <a
              href="#"
              routerLink="/engagement/competition-list"
              ng-class="navClass('competitionList')"
              id="menu-toggleadminCompetition"
              ><i class="fa fa-child fa-lg"></i
              ><span class="nav-text">{{
                engagementLabel.competition
              }}</span></a
            >
          </li>
          <li [class]="activepath === '/engagement/survey-list' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/engagement/survey-list')" >
            <a
              href="#"
              routerLink="/engagement/survey-list"
              id="menu-toggleadminCompetition"
              ng-class="navClass('surveyList')"
              ><i class="fa fa-question fa-lg"></i
              ><span class="nav-text">{{ sideBarLabels.survey }}</span></a
            >
          </li>
          <li  [class]="activepath === '/engagement/deal-list' ? 'activeItem' : 'dropItem'"
               (click)="setActive('/engagement/deal-list')" >
            <a
              href="#"
              routerLink="/engagement/deal-list"
              ng-class="navClass('productList')"
              id="menu-toggleadminProduct"
              ><i class="fa fa-product-hunt fa-lg"></i
              ><span class="nav-text">{{ sideBarLabels.product }}</span></a
            >
          </li>
          <li  [class]="activepath === '/engagement/forum list' ? 'activeItem' : 'dropItem'"
               (click)="setActive('/engagement/forum list')"  >
            <a
              href="#"
              routerLink="/engagement/forum list"
              ng-class="navClass('forumList')"
              id="menu-toggleadminCompetition"
              ><i class="fa fa-quora"></i
              ><span class="nav-text">{{ engagementLabel.forum }}</span></a
            >
          </li>
        </ul>
      </li>

      <!--********************** Near me **********************-->
      <li>
        <a
          href=""
          data-toggle="collapse"
          data-target="#submenuNearMe"
          aria-expanded="false"
        >
          <i class="fa fa-street-view fa-lg"></i>
          <span class="nav-text">{{ sideBarLabels.nearMe }}</span>
        </a>
        <ul
          class="nav collapsable collapse"
          id="submenuNearMe"
          role="menu"
          aria-labelledby="btn-Settings"
        >
          <li [class]="activepath === '/nearMe/cfc-list' ? 'activeItem' : 'dropItem'"
             (click)="setActive('/nearMe/cfc-list')" >
            <a href="#" routerLink="/nearMe/cfc-list" id="menu-toggleadminFaq"
              ><i class="fa fa-building-o fa-lg"></i
              ><span class="nav-text">{{ sideBarLabels.CFC }}</span></a
            >
          </li>
          <li [class]="activepath === '/nearMe/nearMe-list' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/nearMe/nearMe-list')" >
            <a
              href="#"
              routerLink="/nearMe/nearMe-list"
              id="menu-toggleadminFaq"
              ><i class="fa fa-building-o fa-lg"></i
              ><span class="nav-text">{{ sideBarLabels.nearMeInfo }}</span></a
            >
          </li>
        </ul>
      </li>

      <!--********************** Services **********************-->
      <li>
        <a
          href=""
          data-toggle="collapse"
          data-target="#submenuGeneral"
          aria-expanded="false"
        >
          <i class="fa fa-tty fa-lg"></i>
          <span class="nav-text">{{ sideBarLabels.services }}</span>
        </a>
        <ul
          class="nav collapsable collapse"
          id="submenuGeneral"
          role="menu"
          aria-labelledby="btn-Settings"
        >
          <li [class]="activepath === '/services/faq-list' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/services/faq-list')" >
            <a href="#" routerLink="/services/faq-list" id="menu-toggleadminFaq"
              ><i class="fa fa-question-circle fa-lg"></i
              ><span class="nav-text">{{ sideBarLabels.FAQ }}</span></a
            >
          </li>
          <!-- <li class="dropItem" ng-class="navClass('brandList')"><a href="#/brandList"
                        id="menu-toggleadminBrand"><i class="fa fa-btc fa-lg"></i><span
                            class="nav-text">{{sideBarLabels.brand}}</span></a></li> -->
          <li [class]="activepath === '/services/system-servise' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/services/system-servise')" >
            <a
              href="#"
              routerLink="/services/system-servise"
              id="menu-toggleadminEnableDisable"
              ><i class="fa fa-question-circle fa-lg"></i
              ><span class="nav-text">{{
                sideBarLabels.systemServices
              }}</span></a
            >
          </li>
          <!-- <li class="dropItem"><a href="#/complaintList" id="menu-toggleadmincomplaint"><i
                        class="fa fa-envelope fa-lg"></i><span class="nav-text">{{sideBarLabels.complaint}}</span></a>
                    </li> -->
          <li [class]="activepath === '/services/webinar' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/services/webinar')" >
            <a
              href="#"
              routerLink="/services/webinar"
              id="menu-toggleadminwebinar"
              ><i class="fa fa-television fa-lg"></i
              ><span class="nav-text">{{ sideBarLabels.webinar }}</span></a
            >
          </li>
          <!-- <li class="dropItem"><a href="#/termsAndConditionList" id="menu-toggleadminTermsAndCondition"><i
                        class="fa fa-legal fa-lg"></i><span class="nav-text">{{sideBarLabels.termsAndConditions}}</span></a>
                    </li> -->
        </ul>
      </li>

      <!--********************** GROUP **********************-->
      <li
        *ngIf="
          currentUserDetail.isContentHead || currentUserDetail.isOperationalHead
        "
      >
        <a
          href="#"
          routerLink="/user/group"
          data-toggle="collapse"
          aria-expanded="false"
        >
          <i class="fa fa-users fa-lg"></i>
          <span class="nav-text"> {{ sideBarLabels.group }} </span>
        </a>
      </li>

      <!--********************** COUPONS **********************-->
      <!-- <li>
                <a href="#/couponsList" >
                    <i class="fa fa-ticket"></i> <span class="nav-text"> {{sideBarLabels.coupon}}</span>
                </a>
            </li> -->

      <!--********************** Site Info (Language) **********************-->
      <!-- *ngIf="currentUserDetail.isBenefitManager || currentUserDetail.isContentHead || currentUserDetail.isNotification" -->
      <li>
        <a
          href=""
          data-toggle="collapse"
          data-target="#submenuLanguage"
          aria-expanded="false"
        >
          <i class="fa fa-info-circle fa-lg"></i>
          <span class="nav-text">{{ sideBarLabels.language }} </span>
        </a>
        <ul
          class="nav collapsable collapse"
          id="submenuLanguage"
          role="menu"
          aria-labelledby="btn-Settings"
        >
          <!-- <li class="dropItem"><a href="#/citizenLanguage" id="menu-toggleadminCitizenlanguage"><i
                                class="fa fa-language fa-lg"></i><span
                                class="nav-text">{{sideBarLabels.citizenLanguage}}</span></a></li> -->
          <li [class]="activepath === '/siteInfo/aboutUs' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/siteInfo/aboutUs')" >
            <a
              href="#"
              routerLink="/siteInfo/aboutUs"
              id="menu-toggleadminAboutus"
              ><i class="fa fa-sticky-note-o fa-lg"></i
              ><span class="nav-text">{{ sideBarLabels.aboutUs }}</span></a
            >
          </li>
          <li [class]="activepath === '/siteInfo/governingBody-info' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/siteInfo/governingBody-info')" >
            <a
              href="#"
              routerLink="/siteInfo/governingBody-info"
              id="menu-toggleadminCommissionerInfo"
              ><i class="fa fa-book fa-lg"></i
              ><span class="nav-text">{{
                sideBarLabels.governingBody
              }}</span></a
            >
          </li>
          <li [class]="activepath === '/siteInfo/city-info' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/siteInfo/city-info')" >
            <a
              href="#"
              routerLink="/siteInfo/city-info"
              id="menu-toggleadminCityInfo"
              ><i class="fa fa-building fa-lg"></i
              ><span class="nav-text">{{
                sideBarLabels.cityInformation
              }}</span></a
            >
          </li>
          <li [class]="activepath === '/siteInfo/tourism-info' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/siteInfo/tourism-info')" >
            <a
              href="#"
              routerLink="/siteInfo/tourism-info"
              id="menu-toggleadminTourismInfo"
              ><i class="fa fa-train fa-lg"></i
              ><span class="nav-text">{{
                sideBarLabels.tourismInformation
              }}</span></a
            >
          </li>

          <li [class]="activepath === '/siteInfo/zone-info' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/siteInfo/zone-info')" >
            <a
              href="#"
              routerLink="/siteInfo/zone-info"
              id="menu-toggleadminZoneInfo"
              ><i class="fa fa-podcast fa-lg"></i
              ><span class="nav-text">{{
                sideBarLabels.zoneInformation
              }}</span></a
            >
          </li>

          <li [class]="activepath === '/siteInfo/corporator-info' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/siteInfo/corporator-info')" >
            <a
              href="#"
              routerLink="/siteInfo/corporator-info"
              id="menu-toggleadminCorporateInfo"
              ><i class="fa fa-life-ring fa-lg"></i
              ><span class="nav-text">{{
                sideBarLabels.corporatorInformation
              }}</span></a
            >
          </li>

          <li [class]="activepath === '/siteInfo/contactUs' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/siteInfo/contactUs')" >
            <a
              href="#"
              routerLink="/siteInfo/contactUs"
              id="menu-toggleadminContact"
              ><i class="fa fa-phone-square fa-lg"></i
              ><span class="nav-text">{{ sideBarLabels.contactUs }}</span></a
            >
          </li>
        </ul>
      </li>

      <!--********************** Site setting **********************-->
      <li *ngIf="currentUserDetail.isOperationalHead">
        <a
          href=""
          data-toggle="collapse"
          data-target="#submenuSetting"
          aria-expanded="false"
        >
          <i class="fa fa-cogs fa-lg"></i>
          <span class="nav-text">siteSetting</span>
        </a>
        <ul
          class="nav collapsable collapse"
          id="submenuSetting"
          role="menu"
          aria-labelledby="btn-Settings"
        >
          <li [class]="activepath === '/siteSetting/bannerSetting' ? 'activeItem' : 'dropItem'"
              (click)="setActive('/siteSetting/bannerSetting')" >
            <a
              href="#"
              routerLink="/siteSetting/bannerSetting"
              id="menu-toggleadminCitizenlanguage"
              ><i class="fa fa-image fa-lg"></i
              ><span class="nav-text">{{ sideBarLabels.banner }}</span></a
            >
          </li>
          <!-- <li class="darkerli"><a href="#" routerLink="/siteSetting/colourSetting"
                            id="menu-toggleadminCitizenlanguage"><i class="fa fa-paint-brush  fa-lg"></i><span
                                class="nav-text">{{sideBarLabels.color}}</span></a>
                    </li>
                    <li class="darkerli"><a href="#" routerLink="/siteSetting/labelSetting"
                            id="menu-toggleadminCitizenlanguage"><i class="fa fa-cog fa-lg"></i><span
                                class="nav-text">{{sideBarLabels.applicationLabels}}</span></a>
                    </li>
                    <li class="darkerli"><a href="#" routerLink="/siteSetting/mobileBanner"
                            id="menu-toggleadminCitizenlanguage"><i class="fa fa-mobile fa-2x"></i><span
                                class="nav-text">{{sideBarLabels.mobileBanner}}</span></a>
                    </li>
                    <li class="darkerli"><a href="#" routerLink="/siteSetting/introPopup"
                            id="menu-toggleadminCitizenlanguage"><i class="fa fa-info fa-lg"></i><span
                                class="nav-text">{{sideBarLabels.introPopup}}</span></a>
                    </li>
                    <li class="darkerli"><a href="#" routerLink="/siteSetting/introPopupMobile"
                            id="menu-toggleadminCitizenlanguage"><i class="fa fa-info fa-lg"></i><span
                                class="nav-text">{{sideBarLabels.introPopupForMobile}}</span></a>
                    </li> -->
        </ul>
      </li>
      <!--********************** Smart City **********************-->
      <!-- <li *ngIf="!currentUserDetail.isBenefitManager">
                <a href="" data-toggle="collapse" data-target="#submenuSmartCity" aria-expanded="false">
                    <i class="fa fa-th-large fa-lg"></i> <span class="nav-text">smartCity</span>
                </a>
                <ul class="nav collapsable collapse" id="submenuSmartCity" role="menu" aria-labelledby="btn-Settings">
                    <li class="darkerli"><a href="#" routerLink="/smartCity/document"
                            id="menu-toggleadminCitizenlanguage"><i class="fa fa-file fa-lg"></i><span
                                class="nav-text">{{sideBarLabels.document}}</span></a></li>
                    <li class="darkerli"><a href="#" routerLink="/smartCity/gallery"
                            id="menu-toggleadminCitizenlanguage"><i class="fa fa-picture-o fa-lg"></i><span
                                class="nav-text">{{sideBarLabels.gallery}}</span></a>
                    </li>
                    <li class="darkerli"><a href="#" routerLink="/smartCity/mom"
                            id="menu-toggleadminCitizenlanguage"><i class="fa fa-pencil-square fa-lg"></i><span
                                class="nav-text">{{sideBarLabels.mom}}</span></a>
                    </li>
                </ul>
            </li> -->

      <!--********************** Corporator **********************-->
      <!-- <li>
                <a href="" data-toggle="collapse" data-target="#submenuCorporated" aria-expanded="false">
                    <i class="fa fa-th-large fa-lg"></i> <span class="nav-text">{{sideBarLabels.webApplication}}</span> </a>
                <ul class="nav collapsable collapse" id="submenuCorporated" role="menu" aria-labelledby="btn-Settings">
                    <li class="dropItem"><a href="#/documentList" id="menu-toggleadminDocument"><i
                                class="fa fa-file-o fa-lg"></i><span class="nav-text">{{sideBarLabels.document}}</span></a>
                    </li>
                    <li class="dropItem"><a href="#/pcsclImageList" id="menu-toggleadminGallery"><i
                                class="fa fa-picture-o fa-lg"></i><span class="nav-text">{{sideBarLabels.gallery}}</span></a>
                    </li>
                    <li class="dropItem"><a href="#/momList" id="menu-toggleadminMom"><i
                                class="fa fa-pencil-square-o fa-lg"></i><span class="nav-text">{{sideBarLabels.mom}}</span></a>
                    </li>
                </ul>
            </li> -->

      <!--********************** Approval **********************-->
      <!-- <li *ngIf="!currentUserDetail.isOperationalHead">
                <a href="#/selectApproval" data-toggle="collapse" data-target="#submenuSetting1" aria-expanded="false">
                    <i class="fa fa-shield  fa-lg"></i> <span class="nav-text"> {{sideBarLabels.approval}} </span>
                </a>
            </li> -->

      <!--********************** LOGS **********************-->
      <!-- <li ng-show={{isOperationalHead}}>
                <a href="#/selectLogs" data-toggle="collapse" data-target="#submenuSetting2" aria-expanded="false">
                    <i class="fa fa-list  fa-lg"></i> <span class="nav-text"> {{sideBarLabels.log}} </span>
                </a>
            </li> -->
      <!--********************** Approval **********************-->
      <!-- <li class="dropItem"><a href="#" routerLink="/smartCity/mom" id="menu-toggleadminMom"><i
                        class="fa fa-pencil-square-o fa-lg"></i><span class="nav-text">{{sideBarLabels.mom}}</span></a>
            </li> -->
      <li *ngIf="currentUserDetail.isOperationalHead" routerLink="/approval">
        <a
          href="#"
          data-toggle="collapse"
          data-target="#submenuApproval"
          aria-expanded="false"
        >
          <i class="fa fa-shield fa-lg fa-lg"></i>
          <span class="nav-text">{{ sideBarLabels.approval }}</span>
        </a>
      </li>
      <!--********************** Notification **********************-->
      <li
        routerLink="/notification/notificationAll"
        *ngIf="
          currentUserDetail.isBenefitManager ||
          currentUserDetail.isContentHead ||
          currentUserDetail.isNotification
        "
      >
        <a
          href="#"
          data-toggle="collapse"
          data-target="#submenuNotification"
          aria-expanded="false"
        >
          <i class="fa fa-envelope fa-lg" aria-hidden="true"></i>
          <span class="nav-text">{{ sideBarLabels.emailNotification }}</span>
        </a>
      </li>

      <li
        routerLink="/notification/push-notification"
        *ngIf="
          currentUserDetail.isBenefitManager ||
          currentUserDetail.isContentHead ||
          currentUserDetail.isNotification
        "
      >
        <a
          href="#"
          data-toggle="collapse"
          data-target="#submenuNotification"
          aria-expanded="false"
        >
          <i class="fa fa-bell fa-lg"></i>
          <span class="nav-text">{{ sideBarLabels.notification }}</span>
        </a>
      </li>

      <!--********************** Campaign **********************-->

      <!-- <li *ngIf="currentUserDetail.isOperationalHead">
                <a href="#" routerLink="/user/campaignList" data-toggle="collapse" aria-expanded="false">
                    <i class="fa fa-bullhorn"></i><span class="nav-text">{{sideBarLabels.campaign}}</span>
                </a>
            </li> -->

      <!--********************** Feedback **********************-->

      <li *ngIf="currentUserDetail.isOperationalHead">
        <a
          href="#"
          routerLink="/user/feedback"
          data-toggle="collapse"
          aria-expanded="false"
        >
          <i class="fa fa-comments"></i
          ><span class="nav-text">{{ sideBarLabels.feedback }}</span>
        </a>
      </li>

      <!--********************** Change language **********************-->
      <li>
        <a
          data-toggle="collapse"
          data-target="#submenuChangeLanguage"
          aria-expanded="false"
        >
          <i class="fa fa-language fa-lg"></i
          ><span class="nav-text">{{ sideBarLabels.changeLanguage }} </span
          ><span class="nav-smalltext">{{ currentLanguageLabel }}</span>
        </a>
        <ul
          class="nav collapsable collapse"
          id="submenuChangeLanguage"
          role="menu"
          aria-labelledby="btn-Settings"
        >
          <li class="dropItem" (click)="changeLanguage('en')">
            <a
              href="#"
              routerLink="/user/citizen-list"
              id="menu-toggleadminBlog"
            >
              <i class="fa fa-font fa-lg"></i>
              <span class="nav-text">English</span>
            </a>
          </li>
          <li class="dropItem" (click)="changeLanguage('mar')">
            <a
              href="#"
              routerLink="/user/citizen-list"
              id="menu-toggleadminBlog"
            >
              <i class="fa fa-lg"
                ><img src="../../assets/images/marathi.png"
              /></i>
              <span class="nav-text">मराठी</span>
            </a>
          </li>
        </ul>
      </li>

      <!--********************** Log OUT **********************-->
      <li (click)="logOutUser()">
        <a
          data-toggle="collapse"
          data-target="#submenuLogout"
          aria-expanded="false"
        >
          <i class="fa fa-sign-out fa-lg"></i>
          <span class="nav-text">{{ sideBarLabels.logout }}</span>
        </a>
        <ul
          class="nav collapsable collapse"
          id="submenuLogout"
          role="menu"
          aria-labelledby="btn-Settings"
        ></ul>
      </li>
    </div>
  </div>
</nav>
