import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { MasterComponent } from './core/master/master.component';

const routes: Routes = [
  {
    path: 'login',
    component:LoginComponent  
  },
  {
    path: '', redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: '', component: MasterComponent,
    children: [
      {
        path:'', redirectTo:'user', pathMatch:'full'
      },
      {
        path:'home',
        loadChildren: ()=> import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path:'user',
        loadChildren: ()=> import('./user/user.module').then(m => m.UserModule)
      },
      {
        path:'engagement',
        loadChildren: ()=> import('./engagement/engagement.module').then(m => m.EngagementModule)
      },
      {
        path:'nearMe',
        loadChildren: ()=> import('./near-me/near-me.module').then(m => m.NearMeModule)
      },
      {
        path:'services',
        loadChildren: ()=> import('./services/services.module').then(m => m.ServicesModule)
      },
      {
        path:'siteInfo',
        loadChildren: ()=> import('./site-info/site-info.module').then(m => m.SiteInfoModule)
      },
      {
        path:'siteSetting',
        loadChildren: ()=> import('./site-setting/site-setting.module').then(m => m.SiteSettingModule)
      },
      {
        path:'smartCity',
        loadChildren: ()=> import('./smart-city/smart-city.module').then(m => m.SmartCityModule)
      },
      {
        path:'notification',
        loadChildren: ()=> import('./notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path:'approval',
        loadChildren: ()=> import('./approval/approval.module').then(m => m.ApprovalModule)
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
