import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"))
    // console.log("From Master Compoenent",user)
    if(user === null){
      this.route.navigate(['/login']);
    }
  }

}
